
    // @ is an alias to /src
    export default {
        name: 'Demos',
        components: {
            
        },
        mounted() {
        }
    }
